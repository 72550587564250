import SubmitEmailField from "./components/SubmitEmailField";
import { ThemeProvider } from "@mui/material";
import { globalTheme } from "./components/styles";
import { LogoHeader } from "./components/LogoHeader";
// import VideoBox from "./components/VideoBox";
import { IntroBox } from "./components/IntroBox";
function App() {
  return (
    <ThemeProvider theme={globalTheme}>
      <div className="App">
        <LogoHeader />
        <IntroBox />
        <SubmitEmailField />
      </div>
    </ThemeProvider>
  );
}

export default App;
