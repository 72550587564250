import { ThemeOptions, createTheme } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#8f4ade",
    },
    secondary: {
      main: "#f50057",
    },

    text: {
      primary: "#ffffff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
    success: {
      main: "#66bb6a",
      dark: "#388e3c",
      light: "#81c784",
    },
  },

  typography: {
    fontFamily: "Georgia, serif",
  },
};

export const globalTheme = createTheme(themeOptions);
