import { Box, Typography } from "@mui/material";
import { introSegments } from "./intro";
import { globalTheme } from "./styles";

export function IntroBox() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1.5rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.5rem",
          width: "50%",
          color: globalTheme.palette.text.secondary,
          textAlign: "center",
        }}
        variant="h1"
      >
        {introSegments.map((segment) => (
          <p>{segment}</p>
        ))}
      </Typography>
    </Box>
  );
}
