import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { globalTheme } from "./styles";
import { addEmailToWaitingList } from "../server/dbInterface";

export default function SubmitEmailField() {
  const [address, setAddress] = useState("");
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressAdded, setAddressAdded] = useState(false);
  let buttonDisabled = address.length === 0 || loading;

  function isValidEmail(givenEmail: string) {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(givenEmail);
  }

  function isValidWebHostName(hostName: string): boolean {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/;
    return regex.test(hostName);
  }

  async function submitAddress(event: any) {
    event.preventDefault();
    if (buttonDisabled) return;
    setAddress(address.trim());
    setLoading(true);

    if (isValidEmail(address) || isValidWebHostName(address)) {
      const result = await addEmailToWaitingList(address);
      if (result) setAddressAdded(true);
      setAddressAdded(true);
    } else {
      setInvalidAddress(true);
    }
    setLoading(false);
  }

  const compStyles = {
    width: "20vmin+20px",
    maxHeight: "50px",
    minWidth: "20vmin",
    margin: "10px",
    borderRadius: "5px",
  };

  const txtToShow = (
    <Typography
      variant="h3"
      sx={{
        color: loading ? globalTheme.palette.text.secondary : "#b1d4d0",
        marginTop: "2vmin",
        marginBottom: "2vmin",
      }}
    >
      {addressAdded ? "Your address has been added!" : "Join the waitlist!"}
    </Typography>
  );

  const formComponents = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <form onSubmit={submitAddress}>
        <TextField
          sx={compStyles}
          label="Email or Nostr nip-05"
          variant="outlined"
          onChange={(event) => {
            setAddress(event.target.value);
            if (invalidAddress) setInvalidAddress(false);
          }}
          error={invalidAddress}
          helperText={invalidAddress ? "Invalid address" : ""}
        />
        <Button
          sx={compStyles}
          variant="contained"
          disabled={buttonDisabled}
          // onClick={submitAddress}
          value={address}
          type="submit"
        >
          {loading ? <CircularProgress /> : "Submit"}
        </Button>
      </form>
    </Box>
  );

  const postAddEmail = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          ...compStyles,
          color: globalTheme.palette.text.primary,
          marginTop: "2vmin",
          marginBottom: "2vmin",
        }}
        variant="h6"
      >
        {address} &emsp; &#10004;
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "50vh",
        minWidth: "100%",
      }}
    >
      {txtToShow}
      {addressAdded ? postAddEmail : formComponents}
    </Box>
  );
}
