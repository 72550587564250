import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { database } from "./firebase";

/**
 * Write a function that adds an email to the 'waiting_list' collection in the firestore database.
 */
export const addEmailToWaitingList = async (
  givenEmail: string
): Promise<boolean> => {
  const email = givenEmail.toLowerCase().trim();
  const docRef = doc(database, "waiting_list", email);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    const timeStampInSeconds = Math.floor(Date.now() / 1000);
    await setDoc(docRef, {
      subscriptionDate: timeStampInSeconds,
    });
    return true;
  }
  return false;
};

/**
 * Fetch the number of emails in the 'waiting_list' collection in the firestore database.
 */
export const getNumberOfEmailsInWaitingList = async (): Promise<number> => {
  const querySnapshot = await getDocs(collection(database, "waiting_list"));
  return querySnapshot.size;
};
