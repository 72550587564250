import { Box, Typography } from "@mui/material";

export const LogoHeader = () => {
  const img = require("../images/logo.png");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      <img src={img} alt="Zebro Logo" width="6%" />
      <Typography
        sx={{
          fontSize: "5rem",
          fontWeight: "bold",
          color: "#b1d4d0",
          marginLeft: "1rem",
        }}
        variant="h1"
      >
        Zebro
      </Typography>
    </Box>
  );
};
