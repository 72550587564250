// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCGPLsuIlhj2vtmgHCbC9leTJ-ep39tsmE",
  authDomain: "zebrohome.firebaseapp.com",
  projectId: "zebrohome",
  storageBucket: "zebrohome.appspot.com",
  messagingSenderId: "368111288493",
  appId: "1:368111288493:web:67c4201ee82b827f99c624",
  measurementId: "G-6K4SEQ4JWC",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const database = getFirestore(app);
